#crm {
    .section {
        .delete-stage {
            img {
                margin-top: 18px;
                width: 15px;
            }
        }
    }
    .columns {
        display: inline-flex;
        min-width: 100%;
        background-color: #fff;
        border: solid 1px #ccc;
        border-radius: 3px;

        
        .column {
            flex: 1;
            border-right: solid 1px #ccc;
            min-height: calc(100vh - 82px);
            min-width: 200px;        

            &:last-child {
                border-right: none;
            }

            .column-title {
                display: flex;
                flex-direction: column;
                border-bottom: solid 1px #ccc;
                padding: 5px;

                h2 {
                    text-transform: none;
                    font-size: 16px;
                    margin-bottom: 3px;
                }

                span {
                    font-size: 14px;
                    color: #5B5B5B;
                }
            }

            &.hovered {
                background-color: #ececec;
            }

            .card {
                &.gray {
                    background: #EFEFEF;

                    a {
                        color: #000;
                    }
                }

                &.blue {
                    background-color: #3367B7;

                    a {
                        color: #fff;
                    }
                }

                &.red {
                    background-color: #ED5565;

                    a {
                        color: #fff;
                    }
                }

                border: solid 1px #ccc;
                margin: 5px;
                padding: 5px;
                border-radius: 3px;
                position: relative;

                .card-label {
                    border-radius: 3px;
                    display: inline-flex;
                    padding-left: 10px;
                    padding-right: 10px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;
                    width: auto;

                    span {
                        font-size: 13px;
                    }
                }

                .card-info {
                    display: flex;
                    align-items: center;
                    img {
                        width: 12px;
                        height: 12px;
                        object-fit: contain;
                        object-position: center;
                        margin-right: 3px;
                    }

                    span {
                        font-size: 14px;

                        &.days {
                            opacity: 0.6;
                        }
                    }

                    strong {
                        margin: 6px 0;
                        font-size: 14px;
                        font-weight: 500;
                        opacity: 0.7;
                    }

                }

                select {
                    position: absolute;
                    bottom: 3px;
                    right: 5px;
                }

                &.updating {
                    opacity: .5 !important;
                    pointer-events: none;
                }
            }
        }
    }   
}