$user-height: 80px; 

#sidebar {
    height: 100vh;
    width: $sidebar-width;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba($color: #FFF, $alpha: 0.2);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba($color: #FFF, $alpha: 0.2);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba($color: #FFF, $alpha: 0.2);
    }

    #menu {
        height: calc(100vh - $user-height);
        overflow-y: auto;

        

        ul {
            overflow-y: auto;
            
            li {
                cursor: pointer;
                margin-bottom: 5px;
    
                .alert {
                    font-size: 12px;
                    background-color: rgb(175, 10, 10);
                    padding: 3px 6px;
                    border-radius: 3px;
                    position: absolute;
                    right: 25px;
                }
                
                a {
                    position: relative;
                    display: flex;
                    padding: 12px 15px;
                    color: #fff;
                    font-weight: 400;
                    font-size: 14px;
                    align-items: center;
                    transition: .3s;
                    margin: 0;
        
                    img {
                        margin-right: 15px;
        
                        &.arrow {
                            position: absolute;
                            right: 15px;
                            transition: .3s;
                        }
                    }
                }
        
                ul {
                    display: none;
                    padding: 8px 0;
        
                    li {
                        a {
                            padding: 6px 20px;
                            .dot {
                                display: table;
                                width: 10px;
                                height: 10px;
                                border: solid 1px $white-color;
                                border-radius: 100%;
                                margin-right: 15px;
                                margin-left: 3px;
                                transition: .3s;
                            }
                        }
        
                        &.active, &:hover {
                            font-weight: 900;
                            .dot {
                                background-color: $white-color;
                            }
                        }
                    }
                }
    
                &.active {
                    .arrow {
                        transform: rotate(-90deg);
                    }
                    ul {
                        display: block;
                    }
                }
            }
        }
    
        > ul > li {
            &.active, &:hover {
                > a {
                    background-color: #313131;
                }
            }
        }
    }

    #user {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $user-height;
        border-bottom: solid 1px #3c3c3c;

        p {
            color: $white-color;
            font-size: 14px;
            margin: 5px 0;

            a {
                text-decoration: underline;
                color: $white-color;
            }
        }

    }
}