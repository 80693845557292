.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-color;
    z-index: 9;
    align-items: center;
    justify-content: center;

    h2 {
        color: $text-secondary-color;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
    }

    &.show {
        display: flex;
    }

    &.hide {
        display: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        background-color: $white-color;
        height: calc(100vh - 100px);
        width: calc(100vw - 100px);
        max-width: 600px;

        &.small {
            max-width: 400px;
        }

        &.regular {
            max-width: 600px;
        }

        &.big {
            max-width: 800px;
        }

        &.medium {
            max-width: 700px;
        }

        .header {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px $box-border-color;

            button {
                padding-right: 5px;
                margin: 0;
            }
        }

        .body {
            flex: 1;
            padding: 20px;
            background-color: $bg-page;
            overflow-y: auto;

            .section {
                border: solid 1px $box-border-color;
                background-color: $white-color;
                padding: 15px 15px 0;

                p {
                    font-size: 14px;
                    margin-bottom: 2px;
                }

                b {
                    font-weight: 500;
                    color: rgb(56, 56, 56);
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            .modal-section-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;

                &:not(:first-child) {
                    padding-top: 5px;
                }

                h2 {
                    color: $text-secondary-color;
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0;
                }

                .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }


        .footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: $half-elements-margin;
            border-top: solid 1px $box-border-color;

            > * {
                margin: $half-elements-margin;
            }
        }
    }
}