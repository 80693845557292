.timeline {
    .actions {
        display: flex;
        margin-top: 5px;
        margin-bottom: 15px;

        button {
            margin-right: 5px;
        }
    }

    .search {
        width: 100%;
        padding: 8px;
        border-radius: 5px;
        border: solid 1px #ccc;
    }

    .activities {
        > p {
            margin-top: 25px;
            margin-bottom: 15px;
            text-align: center;
        }
        .activity {
            font-size: 14px;
            margin-top: 20px;

            display: flex;
            align-items: flex-start;
            padding-bottom: 15px;

            &:not(:last-child) {
                border-bottom: solid 1px #ccc;
            }

            .icon {
                width: 30px;
                height: 30px;
                object-fit: contain;
                object-position: top left;
                margin-top: 3px;
            }
            
            .details {
                margin-left: 20px;
                width: 100%;
                
                .header {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .data {
                        display: flex;
                        flex-direction: column;
                        font-size: 16px;
                        
                        .date {
                            margin-top: 5px;
                            font-size: 14px;
                            color: #657072;
                        }
                    }

                    .actions {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        a {
                            display: table;
                            margin-left: 20px;
                        }
                    }
                }
                
                .content {
                    margin-top: 10px;
                }
            }
        }
    }
}