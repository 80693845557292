.box-shrinkable {
    margin-bottom: $elements-margin;
    border: solid 1px $box-border-color;

    > .header {
        display: flex;
        border-bottom: solid 1px $box-border-color;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px;

        button {
            background: transparent;
            border: none;
            width: 30px;
            height: 30px;
        }

        span {
            color: $text-secondary-color;
            font-weight: 500;

            small {
                color: #969696;
            }
        }
    }

    .body {
        background-color: $white-color;
        padding: 20px;
        
        border-radius: 3px;
        height: 100%;
    }

    &.closed {
        border-bottom: 0;
        
        .body {
            display: none;
        }

        .header button img {
            transform: rotate(180deg);
        }
    }
}