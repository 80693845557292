$stage-border-radius: 4px;

#crm-card {
    .input-preview {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .box-edit {
            z-index: 2;
            background-color: #fff;
            padding: 10px;
            box-shadow: 0px 6px 9px rgb(0 0 0 / 30%);
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            .actions {
                display: flex;
                justify-content: flex-end;

                button {
                    margin-left: 5px;
                }
            }
        }

        > .content {
            flex: 1;

            label {
                font-weight: 500;
                font-size: 14px;
                color: #696969;
            }
            p {
                color: #878787;
                font-size: 14px;
            }
        }

        > .actions {
            &:not(.edit){
                opacity: 0;
            }

            padding-top: 5px;

            button {
                width: 30px;
                height: 50px;
                margin-left: 3px;
                border: none;
                background-color: transparent;

                img {
                    width: 25px;
                    height: 25px;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        &:hover {

            .actions {
                opacity: 1;
            }
        }
    }

    > .body {
        margin-top: $elements-margin;;
    }
    > .header {
        background-color: #fff;
        padding: 20px;

        .stage-infos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            min-height: 35px;

            .card-status {
                span {
                    padding: 2px 5px;
                    border-radius: 3px;
                    margin-left: 4px;
                    color: #fff;
    
                    &.won {
                        background-color: green;
                    }
    
                    &.lost {
                        background-color: #a1a1a9;
                        
                    }
    
                    &.frozen {
                        background-color: #3367B7;
                    }
                }
            }

            .temperature-info {
                span {
                    padding: 2px 5px;
                    border-radius: 3px;
                    margin-left: 4px;

                    &.hot {
                        background-color: red;
                        color: #fff;
                    }

                    &.warm {
                        background-color: #F8AC59;
                        color: #fff;
                    }

                    &.cold {
                        background-color: #ccc;
                    }
                }
            }

            .card-actions {
                display: flex;

                button {
                    margin-left: 5px;
                }
            }
        }
    
        .stages {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .stage {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                flex: 1;
                border: none;
                background-color: #ccc;
                margin-left: -5px;
                padding-left: 5px;
                padding-right: 12px;
                height: 65px;
                overflow: hidden;
                
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 15px;
                    height: 100%;
                    background-image: url(../../../assets/images/svgs/card-stage-arrow.svg);
                    background-size: contain;
                    background-position: right;
                    background-repeat: no-repeat;
                    z-index: 9;
                }

                &:hover, &.active {
                    background-color: #1AB394;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 15px;
                        height: 100%;
                        background-image: url(../../../assets/images/svgs/card-stage-arrow-hover.svg);
                        background-size: 100%;
                        background-position: right;
                        background-repeat: no-repeat;
                    }
                }

                &:first-child {
                    border-radius: $stage-border-radius 0 0 $stage-border-radius;
                }

                &:last-child {
                    border-radius: 0 $stage-border-radius $stage-border-radius 0;

                    &::after {
                        display: none;
                    }
                }
                

                img {
                    pointer-events: none;
                }

                .stage-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    
                    span {
                        color: #fff;
                        font-size: 13px;
                        text-align: center;
                        line-height: 16px;
                        
                        &:first-child {
                            margin-bottom: 3px;
                        }
                    }
                }
            }
        }
    }
}