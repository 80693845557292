.indicator {
    &.positive {
        color: blue;
    }
    &.negative {
        color: red;
        font-weight: bold;
    }
}

.modal.product-details .row > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-finance {
    padding-top: 16px;
}

.order-conference .row > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input-group-with-link {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 18px;
    padding-top: 18px;

    a {
        position: absolute;
        bottom: 14px;
        font-size: 14px;
    }
}

.tag-conference-status {
    padding: 6px 8px;
    font-weight: 500;
    color: #fff;
    border-radius: 15px;
    font-size: 12px;
    white-space: nowrap;
    &.pending {
        background-color: red;
    }

    &.conferred {
        background-color: green;
    }
}