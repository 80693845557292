#category-list {
    .categories {
        > ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            > li {
                margin-bottom: $elements-margin;
                
                > ul {
                    display: none;

                    > li:last-child {
                        border-bottom: solid 1px $box-border-color;
                    }
                }

                .arrow {
                    transition: .3s;
                }

                &.active {
                    .arrow {
                        transform: rotate(-90deg);
                    }

                    > ul {
                        display: block;
    
                    }
                }
            }
        }

        ul {
            list-style: none;
        }

        li button {
            background-color: transparent;
            border: none;
            padding-left: 12px;
            display: flex;
            align-items: center;
            height: 45px;

            > span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                color: $text-secondary-color;
                width: 0;
                flex-grow: 1;
                text-align: left;
                // display: flex;
                // justify-content: space-between;
                // align-items: center;

                .products-count {
                    color: #ccc;
                    font-size: 10px;
                }
            }
        }

        .category {
            display: flex;
            width: 100%;
            background-color: #FFF;
            border: solid 1px $box-border-color;

            > button:nth-child(1) {
                flex: 1;
                font-size: 14px;
                color: $text-secondary-color;

                > img {
                    width: 15px;
                    height: 15px;
                    object-fit: contain;
                    margin-right: 15px;
                }
            }

            > button:not(:nth-child(1)) {
                width: 35px;
            }
        }

        .subcategory {
            display: flex;
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
            align-items: center;
            border-left: solid 1px $box-border-color;
            border-right: solid 1px $box-border-color;
            min-height: 46px;

            &.dark {
                background-color: #F0F0F0;
            }
            &.light {
                background-color: #FFF;
            }

            .category-desc {
                flex: 1;
                display: flex;
                align-items: center;

                > img {
                    width: 15px;
                    height: 15px;
                    object-fit: contain;
                    margin-right: 15px;
                }

                > span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    color: $text-secondary-color;
                    width: 0;
                    flex-grow: 1;
                }
            }

            button {
                width: 35px;
                height: 45px;
            }
        }
    }
}