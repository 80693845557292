//general
$primary-color: #000;
$secondary-color: #c82b65;
$secondary-light-color: rgb(232, 239, 249);
$secondary-dark-color: #EF3826;
$action-color: #170C52;
$link-color: #134CD8;
$white-color: #fff;
$bg-page: #ECF0F5;
$text-primary-color: #000;
$text-secondary-color: #696969;
$text-tertiary-color: #AAA3A3;
$border-radius-default: 3px;

$elements-margin: 15px;
$half-elements-margin: calc($elements-margin / 2);

//sidebar
$sidebar-width: 260px;

//boxes
$box-border-color: #ccc;

//modals
$overlay-color: rgba(0, 0, 0, 0.7);


//inputs
$input-label-weight: 400;
$input-label-color: #696969;
$input-label-font-size: 13px;
$input-border-width: 1px;
$input-border-color: #696969;
$input-margin-bottom: 20px;
$input-boxes-height: 250px;

//breakpoints
$breakpoint-desk: "only screen and (min-width: 1280px)";
$breakpoint-tablet-land: "only screen and (min-width: 975px)";
$breakpoint-tablet: "only screen and (min-width: 600px)";
$breakpoint-mobile-land: "only screen and (min-width: 480px)";